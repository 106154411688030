import { React, useContext, useEffect, useState } from "react";
import { Button, Spinner, useDisclosure, SimpleGrid, Box, Image, Text, Badge, Flex, Center, Input} from "@chakra-ui/react";
import MintButtonNFT from "./MintButtonNFT";
import { useNavigate  } from "react-router-dom";
import { isBrowser, isMobile} from "react-device-detect";
import { AppContext } from "../context";
import { useWeb3 } from "../hooks/useWeb3";
import { usePAN } from "../hooks/usePAN";
import { useNFT } from "../hooks/useNFT";

export default function NFTMint({tokenImages}) {
  const {
    web3Provider,
    PancakeNft_address,
    PAN_address,
    isLoading,
    setIsLoading,
    setIsWalletOpen
  } = useContext(AppContext);

  const { connectWeb3 } = useWeb3();
  const { checkAllowance, approveToken } = usePAN();
  const { mintPancake } = useNFT();

  const [direction, setDirection]       = useState('increasing');
  const [brightness, setBrightness]     = useState(100);

  const [mintAmount,   setMintAmount]   = useState('1');
  const [allowed,      setAllowed]      = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [currentLevel, setCurrentLevel] = useState('1');
  const [currentPower, setCurrentPower] = useState('200');

  const checkStatus = async (amount) => {
    console.log("check", amount);
    if (web3Provider) {
      try {
        var result = await checkAllowance(PAN_address, amount, PancakeNft_address);
        setAllowed( result ); 

        //console.log(amount, allowed, result, PAN_address);
      } catch (error) {
        console.error(error);
      }
    }
  };


  useEffect(() => {

    const brightnessInterval = setInterval(() => {
      setBrightness(prevBrightness => {
        if (prevBrightness >= 100) {
          setDirection('decreasing');
          return prevBrightness - 5;
          //return 100;
        } else if (prevBrightness <= 85) {
          setDirection('increasing');
          return prevBrightness + 5;
          //return 100;
        }
        return direction === 'increasing' ? prevBrightness + 5 : prevBrightness - 5;
      });
    }, 350); // Faster interval for smoother transition    

    // Clean up the intervals on component unmount
    return () => {
      clearInterval(brightnessInterval);
    };
  }, [direction]);


  useEffect(() => {
    let nextIndex = 0;

    // Function to update the image
    const updateImage = () => {
      nextIndex = (nextIndex+1) % tokenImages.length;
      setCurrentImage(tokenImages[nextIndex].url);
      setCurrentLevel(tokenImages[nextIndex].level);
      setCurrentPower(tokenImages[nextIndex].power);
    };

    // Update the image immediately upon mounting
    updateImage();

    // Check Status
    if ((allowed===false))
      {checkStatus(2000*Number(mintAmount));}

    // Set up the interval to update the image every 10 seconds
    const imageInterval  = setInterval(updateImage, 1500);

    // Clean up the intervals on component unmount
    return () => {
      clearInterval(imageInterval);
    };
  }, [tokenImages, mintAmount, allowed]);

  return web3Provider ? (
         
         isMobile ? (

          <Box position="relative" top="20px" justifyContent="center" alignItems="center">
            <Box w="200px" mb="20px"
                 bg="rgb(170,208,180)" style={{ filter: `brightness(${brightness}%)`, transition: 'filter 0.5s ease-in-out' }} border="2px" borderColor="green.600"
                 borderRadius="1rem 1rem 1rem 1rem"
            >
              <Image src={currentImage} boxSize="200px" objectFit="cover" borderRadius="1rem 1rem 0rem 0rem" 
                     mr="0px"
              />

              <Flex justifyContent="space-evenly" alignItems="center" mb="10px" mt="10px">
                <Badge borderRadius="full" fontSize='0.8em' px="2" colorScheme="teal" backgroundColor="rgb(206,225,246)">
                  Level {currentLevel}
                </Badge>
                <Badge borderRadius="full" fontSize='0.8em' px="2" colorScheme="teal" backgroundColor="rgb(206,225,246)">
                  Power {currentPower}
                </Badge>
              </Flex>

            </Box>

            <Box
            w={(isMobile) ? "95vw" : "33rem"}
            mx="auto"
            boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
            borderRadius="1.25rem"
            border="2px" borderColor="green.600"
            >

              <Flex
                alignItems="center"
                p="1rem 1rem 1rem"
                bg="rgb(248,233,211)"
                color="rgb(86, 90, 105)"
                justifyContent="space-between"
                borderRadius="1.25rem 1.25rem 0 0">
                <Text
                  color="black"
                  fontWeight="bold">
                  Mint PANCAKE:
                </Text>

              </Flex>

              <Box
                p="0rem 0.5rem 0.5rem 0.5rem"
                bg="rgb(248,233,211)"
                borderRadius="0 0 0rem 0rem">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="1rem 1rem 1rem"
                  borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                  height="75px"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box>
                    <Text
                      width="70px"
                      color="black"
                      fontWeight="bold">
                      Amount:
                    </Text>
                  </Box>

                  <Box>
                    <Input
                      placeholder="0.0"
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      textAlign="right"
                      bg="rgb(247, 248, 250)"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      type="text"
                      inputMode="decimal"
                      color="black"
                      value={mintAmount}
                      style={{ boxShadow: "none" }}
                      onChange={ (e)  => {let newAmount = e.target.value.replace(/[^0-9.]/g, "").replace(/(\.*)\./g, "$1");
                                          if (Number(newAmount)>30) { newAmount=30; } //limit the max minted NFT per tx
                                          const checkAmount = 2000*Number(mintAmount); setMintAmount(newAmount); checkStatus(checkAmount); } }
                    />
                  </Box>

                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="1rem 1rem 1rem"
                  mt="0.25rem"
                  borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                  height="75px"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                  >
                  <Box>
                    <Text
                      width="70px"
                      color="black"
                      fontWeight="bold">
                      PAN:
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      placeholder="0.0"
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      textAlign="right"
                      bg="rgb(247, 248, 250)"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      type="text"
                      inputMode="decimal"
                      color="black"
                      value={2000*mintAmount}
                      isDisabled
                      style={{ boxShadow: "none", cursor: "auto" }}
                    />
                  </Box>

                </Flex>

              </Box>

              { (allowed===true)  && <MintButtonNFT onClick={async () => { var tx= await mintPancake(Number(mintAmount))
                                                                          if (typeof tx !== 'undefined') {
                                                                            setIsLoading(true);
                                                                            await tx.wait();
                                                                            setIsLoading(false);
                                                                            checkStatus(2000*Number(mintAmount));
                                                                          }              
                                                            }} isLoading={isLoading} text="Mint" allowed={allowed} amount={Number(mintAmount)}/> }

              { (allowed===false) && <MintButtonNFT onClick={async () => { var tx = await approveToken(PAN_address, PancakeNft_address, (100*2000*Number(mintAmount)).toString());
                                                                           if (typeof tx !== 'undefined') {
                                                                            setIsLoading(true);
                                                                            await tx.wait(); 
                                                                            setIsLoading(false);
                                                                            checkStatus(2000*Number(mintAmount));
                                                                           }
                                                            }} isLoading={isLoading} text="Approve" allowed={allowed} /> }

            </Box>
        </Box>          
          
        ) : (

          <Flex position="relative" top={(isMobile) ? "20px" : "20px"} justifyContent="center" alignItems="center">
            <Box w={(isMobile) ? "100px" : "250px"} mr={(isMobile) ? "100px" : "250px"}
                 bg="rgb(170,208,180)" style={{ filter: `brightness(${brightness}%)`, transition: 'filter 0.5s ease-in-out' }} border="2px" borderColor="green.600"
                 borderRadius="1rem 1rem 1rem 1rem"
            >
              <Image src={currentImage} boxSize={(isMobile) ? "100px" : "250px"} objectFit="cover" borderRadius="1rem 1rem 0rem 0rem" 
                    mr={(isMobile) ? "50px" : "150px"}
              />

              <Flex justifyContent="space-evenly" alignItems="center" mb="10px" mt="10px">
                <Badge borderRadius="full" fontSize={(isMobile) ? '0.6em' : '0.8em'} px="2" colorScheme="teal" backgroundColor="rgb(206,225,246)">
                  Level {currentLevel}
                </Badge>
                <Badge borderRadius="full" fontSize={(isMobile) ? '0.6em' : '0.8em'} px="2" colorScheme="teal" backgroundColor="rgb(206,225,246)">
                  Power {currentPower}
                </Badge>
              </Flex>

            </Box>

            <Box
            w={(isMobile) ? "95vw" : "33rem"}
            mx="auto"
            boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
            borderRadius="1.25rem"
            border="2px" borderColor="green.600"
            >

              <Flex
                alignItems="center"
                p="1rem 1rem 1rem"
                bg="rgb(248,233,211)"
                color="rgb(86, 90, 105)"
                justifyContent="space-between"
                borderRadius="1.25rem 1.25rem 0 0">
                <Text
                  color="black"
                  fontWeight="bold">
                  Mint PANCAKE:
                </Text>

              </Flex>

              <Box
                p="0rem 0.5rem 0.5rem 0.5rem"
                bg="rgb(248,233,211)"
                borderRadius="0 0 0rem 0rem">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="1rem 1rem 1rem"
                  borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                  height="75px"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box>
                    <Text
                      width="70px"
                      color="black"
                      fontWeight="bold">
                      Amount:
                    </Text>
                  </Box>

                  <Box>
                    <Input
                      placeholder="0.0"
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      textAlign="right"
                      bg="rgb(247, 248, 250)"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      type="text"
                      inputMode="decimal"
                      color="black"
                      value={mintAmount}
                      style={{ boxShadow: "none" }}
                      onChange={ (e)  => {let newAmount = e.target.value.replace(/[^0-9.]/g, "").replace(/(\.*)\./g, "$1");
                                          if (Number(newAmount)>30) { newAmount=30; } //limit the max minted NFT per tx
                                          const checkAmount = 2000*Number(mintAmount); setMintAmount(newAmount); checkStatus(checkAmount); } }
                    />
                  </Box>

                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="1rem 1rem 1rem"
                  mt="0.25rem"
                  borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                  height="75px"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                  >
                  <Box>
                    <Text
                      width="70px"
                      color="black"
                      fontWeight="bold">
                      PAN:
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      placeholder="0.0"
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      textAlign="right"
                      bg="rgb(247, 248, 250)"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      type="text"
                      inputMode="decimal"
                      color="black"
                      value={2000*mintAmount}
                      isDisabled
                      style={{ boxShadow: "none", cursor: "auto" }}
                    />
                  </Box>

                </Flex>

              </Box>

              { (allowed===true)  && <MintButtonNFT onClick={async () => { var tx= await mintPancake(Number(mintAmount))
                                                                          if (typeof tx !== 'undefined') {
                                                                            setIsLoading(true);
                                                                            await tx.wait();
                                                                            setIsLoading(false);
                                                                          }              
                                                            }} isLoading={isLoading} text="Mint" allowed={allowed} amount={Number(mintAmount)}/> }

              { (allowed===false) && <MintButtonNFT onClick={async () => { var tx = await approveToken(PAN_address, PancakeNft_address, (2000*Number(mintAmount)).toString());
                                                                           if (typeof tx !== 'undefined') {
                                                                            setIsLoading(true);
                                                                            await tx.wait(); 
                                                                            setIsLoading(false);
                                                                            checkStatus(2000*Number(mintAmount));
                                                                           }
                                                            }} isLoading={isLoading} text="Approve" allowed={allowed} /> }

            </Box>
        </Flex>
        )

  ) : (
    <Box p="0.5rem" bg="white" borderRadius="1.25rem 1.25rem 1.25rem 1.25rem">
      <Button
        color="white"
        bg="orange"
        width="100%"
        p="1.62rem"
        borderRadius="1.25rem"
        _hover={{ bg: "orange.400" }}
        onClick={ () => {setIsWalletOpen(true)} }
      >
        Connect Wallet
      </Button>
    </Box>
  );
}
