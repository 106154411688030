import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer,
    Flex,
    Image,
    Text
  } from "@chakra-ui/react";
  import Axios from "axios";
  import { useEffect, useState, useContext } from "react";
  import { isBrowser, isMobile} from "react-device-detect";

  import { useWeb3 } from "../hooks/useWeb3";
  import { AppContext } from "../context";


  export default function AccountModal({isOpen, onClose}) {
    const [search, setSearch] = useState("");
    const {
      selectedCoin,
      setSelectedCoin,
      V1Checked
    } = useContext(AppContext);

    const { handleCryptoOrgExplorer, handleExplorer } = useWeb3();

    const AccountListV1 = [
      {
        name: "Account1",
        address: "cro1l3weyh4fkneyeatdh6jwp0u0t4qha22ycpnq7r",
      },
      {
        name: "Account2",
        address: "cro1zqvl7yj0zcqe7p0qnpg0sk94gpa38hzwz8shjn",
      },
      {
        name: "Account3",
        address: "cro1pduq0ga2ans0sspph6r5hcf77cqypz6de7n64y",
      },
      {
        name: "Account4",
        address: "cro1fncg0fsr8vt30qaqmzqxunnrkxr6a7xxkfpr7y",
      },
    ];

    const AccountListV2 = [
      {
        name: "Account1",
        address: "cro1hhfh6xaflg8zwhwvrs7sgur2pyfunjqeu8wsd6",
      },
      {
        name: "Account2",
        address: "cro1a2vawclcntewtjd5jfjf44dr6mdfdyg8xzfe5t",
      },
      {
        name: "Account3",
        address: "cro1wc43z84u8keas3ffw4ynapwe0hzfen3xx03dpd",
      },
      {
        name: "Account4",
        address: "cro1ujkwlnfnl3mmka4twqx07azxk6djlplddcn48h",
      },
    ];

    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent
          background="orange.100"
          border="0.2rem"
          borderStyle="solid"
          borderColor="orange.300"
          borderRadius="3xl">

          { (V1Checked) && 

            <ModalHeader color="black" px={4} fontSize="lg" fontWeight="bold">
              Stake and unstake account address V1:
            </ModalHeader>

          }

          { (!V1Checked) && 

          <ModalHeader color="black" px={4} fontSize="lg" fontWeight="bold">
            Stake and unstake account address V2:
          </ModalHeader>

          }

          <ModalCloseButton
            color="black"
            fontSize="sm"
            _hover={{
              color: "gray.600",
            }}
            onClick={(e) => onClose()}
            />
          <ModalBody pt={0} px={4}>

            { (V1Checked) && 

              <div>
                <Table variant='unstyle' mt="0.3rem">
                  <Tbody>
                    {AccountListV1
                      .filter((val) => {
                        return val.address.toLowerCase().includes(search.toLowerCase());
                      })
                      .map((val) => {
                        return (
                            <Tr key={val.name}
                                style={{backgroundColor: ""}}
                                _hover={{backgroundColor: "orange.200", cursor: "pointer" }}
                                onClick={() => handleCryptoOrgExplorer( ("/account/" + val.address) )}>
                              <Td>
                                  <Flex alignItems="center" justifyContent="left">
                                  <Box>
                                  <Text fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold" mb="0.3rem">{val.name}</Text>
                                  <Text fontSize={(isMobile) ? '14px' : '16px'} color="#848482" fontWeight="bold">{val.address}</Text>
                                  </Box>
                                  </Flex>
                              </Td>
                          </Tr>
                        );
                      })
                    }
                  </Tbody>
                </Table>
              </div>

            }

            { (!V1Checked) && 

            <div>
              <Table variant='unstyle' mt="0.3rem">
                <Tbody>
                  {AccountListV2
                    .filter((val) => {
                      return val.address.toLowerCase().includes(search.toLowerCase());
                    })
                    .map((val) => {
                      return (
                          <Tr key={val.name}
                              style={{backgroundColor: ""}}
                              _hover={{backgroundColor: "orange.200", cursor: "pointer" }}
                              onClick={() => handleCryptoOrgExplorer( ("/account/" + val.address) )}>
                            <Td>
                                <Flex alignItems="center" justifyContent="left">
                                <Box>
                                <Text fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold" mb="0.3rem">{val.name}</Text>
                                <Text fontSize={(isMobile) ? '14px' : '16px'} color="#848482" fontWeight="bold">{val.address}</Text>
                                </Box>
                                </Flex>
                            </Td>
                        </Tr>
                      );
                    })
                  }
                </Tbody>
              </Table>
            </div>

            }

          </ModalBody>
  
          <ModalFooter
            justifyContent="flex-start"
            background="orange.200"
            borderBottomLeftRadius="3xl"
            borderBottomRightRadius="3xl"
            p="1rem">
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  