import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
//import { ChainId, Fetcher, Token, Route, Trade, TokenAmount, TradeType, Percent, Pair } from '@uniswap/sdk';
import { ChainId, Fetcher, Token, Route, Trade, TokenAmount, TradeType, Percent, Pair } from 'vvs-sdk';
import { AppContext } from "../context";
import axios from 'axios';
import { usePAN } from "./usePAN";

export function useSwap() {
  const {
    WCRO_address,
    walletAddress,
    uniswapRouterAddress,
    web3Provider,
    slippageTolerance,
    multiHops,
    swapCoin1,
    swapCoin2,
  } = useContext(AppContext);

  const { BigNumber } = require('ethers');
  const { checkAllowance, approveToken, getTotalSupply, getBalance } = usePAN();

  const WCRO_ABI              = require("../abis/WCRO_abi.json");
  const UniswapV2Pair_ABI     = require("../abis/UniswapV2Pair_abi.json");
  const UniswapV2ERC20_ABI    = require("../abis/UniswapV2ERC20_abi.json");
  const UniswapV2Router02_ABI = require("../abis/UniswapV2Router02_abi.json");

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  //SWAP COIN AND PAIR//
  const WCRO  = new Token(ChainId.MAINNET, "0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23", 18); //MAINNET
  const ptCRO = new Token(ChainId.MAINNET, "0x51f2AB325A81b0Eb36B0814Ca2fA8A23CB63d731", 18); //MAINNET
  const ytCRO = new Token(ChainId.MAINNET, "0x3D329Eb0eD1f460b4A52c9F70C20a1A5861f74E4", 18); //MAINNET
  const PAN   = new Token(ChainId.MAINNET, "0xDa6c746E8a14EFE6E0A031F5d632bd2B0adC28Be", 18); //MAINNET
  const USDT  = new Token(ChainId.MAINNET, "0x66e428c3f67a68878562e79A0234c1F83c208770", 6 ); //MAINNET
  const USDC  = new Token(ChainId.MAINNET, "0xc21223249CA28397B4B6541dfFaEcC539BfF0c59", 6 ); //MAINNET
  const bCRO  = new Token(ChainId.MAINNET, "0xeBAceB7F193955b946cC5dd8f8724a80671a1F2F", 18); //MAINNET
  const LCRO  = new Token(ChainId.MAINNET, "0x9Fae23A2700FEeCd5b93e43fDBc03c76AA7C08A6", 18); //MAINNET
  //V2
  const ptCROv2 = new Token(ChainId.MAINNET, "0x7BC5e77837D5c5368530F40B8aefdcf951591Fe2", 18); //MAINNET
  const ytCROv2 = new Token(ChainId.MAINNET, "0x476967481e5BFa26e012E12B7DA5a3326ffFe700", 18); //MAINNET
  //

  let pair1   = 0;
  let pair2   = 0;
  let pair3   = 0;
  let pair4   = 0;
  let pair5   = 0;
  let pair6   = 0;
  let pair7   = 0;
  let pair8   = 0;
  let pair9   = 0;
  let pair10  = 0;

  //////////////////////

    async function queryPairs() {
      if ( (web3Provider) && ((pair1===0)|(pair2 ===0)|(pair3===0)|(pair4===0)|(pair5===0)|(pair6===0)|(pair7===0)|(pair8===0)|
                              (pair9===0)|(pair10===0)) ) {
        pair1  = await Fetcher.fetchPairData(ptCRO,   WCRO, web3Provider);
        pair2  = await Fetcher.fetchPairData(ytCRO,   WCRO, web3Provider);
        pair3  = await Fetcher.fetchPairData(PAN,     WCRO, web3Provider);
        pair4  = await Fetcher.fetchPairData(USDT,    WCRO, web3Provider);
        pair5  = await Fetcher.fetchPairData(USDC,    WCRO, web3Provider);
        pair6  = await Fetcher.fetchPairData(USDT,    USDC, web3Provider);
        pair7  = await Fetcher.fetchPairData(bCRO,    WCRO, web3Provider);
        pair8  = await Fetcher.fetchPairData(LCRO,    WCRO, web3Provider);
        //V2
        pair9  = await Fetcher.fetchPairData(ptCROv2, WCRO, web3Provider);
        pair10 = await Fetcher.fetchPairData(ytCROv2, WCRO, web3Provider);
      } 
      else if (web3Provider) {
        pair1  = pair1;
        pair2  = pair2;
        pair3  = pair3;
        pair4  = pair4;
        pair5  = pair5;
        pair6  = pair6;
        pair7  = pair7;
        pair8  = pair8;
        //V2
        pair9  = pair9;
        pair10 = pair10;                
      }
      else {
        pair1  = 0;
        pair2  = 0;
        pair3  = 0;
        pair4  = 0;
        pair5  = 0;
        pair6  = 0;
        pair7  = 0;
        pair8  = 0;
        //V2
        pair9  = 0;
        pair10 = 0;
      }

    }

    async function calAmountOUT(amount, inputToken, outputToken, tradeType) { //Input unit: ether;
      const inputAomunt = amount;
      var obj = 
      {
        amount: "",
        amountTolerance: "",
        priceInToOut: "",
        priceOutToIn: "",        
        path: []
      };

      if ((inputAomunt==="")|(Number(inputAomunt)===0)) { //return if amount = "" or 0
        return (obj);
      }

      if ((inputToken.symbol === "CRO")||(outputToken.symbol === "CRO")) {  // rule for CRO
        obj.amount = inputAomunt;
        obj.amountTolerance = inputAomunt;
        obj.priceInToOut = "1";
        obj.priceOutToIn = "1";

        return (obj);
      }

      try {

        var tradePath

        if (tradeType===0) {  // EXACT INPUT
          tradePath = await calTradePath(inputAomunt, 0, tradeType);

          obj.amount = tradePath.amount;
          obj.amountTolerance = tradePath.amountTolerance;
          obj.priceInToOut = tradePath.priceInToOut;
          obj.priceOutToIn = tradePath.priceOutToIn;
          obj.path = tradePath.path;
          //console.log(obj);

          return (obj);
        }
        else {                // EXACT OUTPUT
          tradePath = await calTradePath(0, inputAomunt, tradeType);

          obj.amount = tradePath.amount;
          obj.amountTolerance = tradePath.amountTolerance;
          obj.priceInToOut = tradePath.priceInToOut;
          obj.priceOutToIn = tradePath.priceOutToIn;
          obj.path = tradePath.path;
          //console.log(obj);
  
          return (obj);
        }    
      } catch (error) {

        if (error.toString()==="InsufficientReservesError") {
          console.log("Insufficient reserves in the liquidity pool.");
        } else {
          console.log('calAmountOUT error occurred:', error);
        }

        return (obj);
      }

    }

    async function calLiquidity(pair, amountDesired, inputToken) { //Input unit: ether;
      const tokenA = new Token(ChainId.MAINNET, pair.tokenA_addr,pair.tokenA_decimals);
      const tokenB = new Token(ChainId.MAINNET, pair.tokenB_addr,pair.tokenB_decimals);

      const tokenPair = await Fetcher.fetchPairData(tokenA, tokenB, web3Provider);

      const reserve0 = tokenPair.reserve0.toFixed(pair.tokenA_decimals);
      const reserve1 = tokenPair.reserve1.toFixed(pair.tokenB_decimals);
      const reserve0_BigN = BigNumber.from(ethers.utils.parseEther(reserve0));
      const reserve1_BigN = BigNumber.from(ethers.utils.parseEther(reserve1));

      if (inputToken === "A") {
        var myTokenA_BigN = BigNumber.from(ethers.utils.parseEther(amountDesired));
        console.log(myTokenA_BigN.toString());

        var amountBDesired;
        var amountB_ether;
        if (tokenPair.token0.address === pair.tokenA_addr) {
          amountBDesired = myTokenA_BigN.mul(reserve1_BigN).div(reserve0_BigN).toString();
          amountB_ether = Number(ethers.utils.formatEther(amountBDesired)).toFixed(8);
          //console.log(tokenPair.token0.address, pair.tokenA_addr, 1);
          //console.log(amountBDesired, amountB_ether);
        }
        else {
          amountBDesired = myTokenA_BigN.mul(reserve0_BigN).div(reserve1_BigN).toString();
          amountB_ether = Number(ethers.utils.formatEther(amountBDesired)).toFixed(8);
          //console.log(tokenPair.token0.address, pair.tokenA_addr, 2);
          //console.log(amountBDesired, amountB_ether);
        }

        return (amountB_ether);
      }
      else if (inputToken === "B") {
        var myTokenB_BigN = BigNumber.from(ethers.utils.parseEther(amountDesired));
        console.log(myTokenB_BigN.toString());

        var amountADesired;
        var amountA_ether;
        if (tokenPair.token0.address === pair.tokenB_addr) {
          amountADesired = myTokenB_BigN.mul(reserve1_BigN).div(reserve0_BigN).toString();
          amountA_ether = Number(ethers.utils.formatEther(amountADesired)).toFixed(8);
          //console.log(tokenPair.token0.address, pair.tokenA_addr, 1);
          //console.log(amountBDesired, amountB_ether);
        }
        else {
          amountADesired = myTokenB_BigN.mul(reserve0_BigN).div(reserve1_BigN).toString();
          amountA_ether = Number(ethers.utils.formatEther(amountADesired)).toFixed(8);
          //console.log(tokenPair.token0.address, pair.tokenA_addr, 2);
          //console.log(amountBDesired, amountB_ether);
        }

        return (amountA_ether);        
      }

    }

    async function calLPShare(pair, amountLP) { //Input unit: ether;
      var obj = 
      {
        amountA: 0,
        amountB: 0
      }

      const tokenA = new Token(ChainId.MAINNET, pair.tokenA_addr, pair.tokenA_decimals);
      const tokenB = new Token(ChainId.MAINNET, pair.tokenB_addr,pair.tokenB_decimals);
      const tokenPair = await Fetcher.fetchPairData(tokenA, tokenB, web3Provider);

      const totalLiquidity = await getTotalSupply(tokenPair.liquidityToken.address);
      const reserve0       = tokenPair.reserve0.toFixed(pair.tokenA_decimals);
      const reserve1       = tokenPair.reserve1.toFixed(pair.tokenB_decimals);
      const reserve0_BigN  = BigNumber.from(ethers.utils.parseEther(reserve0));
      const reserve1_BigN  = BigNumber.from(ethers.utils.parseEther(reserve1));

      var userLiquidity_BigN  = BigNumber.from(ethers.utils.parseEther(amountLP));
      var totalLiquidity_BigN = BigNumber.from(ethers.utils.parseEther(totalLiquidity));

      var amountA_BigN;
      var amountB_BigN;
      if (tokenPair.token0.address === pair.tokenA_addr) {
        amountA_BigN = reserve0_BigN.mul(userLiquidity_BigN).div(totalLiquidity_BigN);
        amountB_BigN = reserve1_BigN.mul(userLiquidity_BigN).div(totalLiquidity_BigN);        
      }
      else {
        amountA_BigN = reserve1_BigN.mul(userLiquidity_BigN).div(totalLiquidity_BigN);
        amountB_BigN = reserve0_BigN.mul(userLiquidity_BigN).div(totalLiquidity_BigN);
      }
      
      obj.amountA       = Number(ethers.utils.formatEther(amountA_BigN.toString())).toFixed(8);
      obj.amountB       = Number(ethers.utils.formatEther(amountB_BigN.toString())).toFixed(8);
      
      //console.log(obj);
      return (obj);
    }

    async function calTradePath(amount1, amount2, tradeType) { //Input unit: ether;
      var obj = 
      {
        amount: "",
        amountTolerance: "",
        priceInToOut: "",
        priceOutToIn: "",
        path: [],
        route: []
      };
      //
      //const WCRO  = new Token(ChainId.MAINNET, "0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23", 18); //MAINNET
      //const ptCRO = new Token(ChainId.MAINNET, "0x51f2AB325A81b0Eb36B0814Ca2fA8A23CB63d731", 18); //MAINNET
      //const ytCRO = new Token(ChainId.MAINNET, "0x3D329Eb0eD1f460b4A52c9F70C20a1A5861f74E4", 18); //MAINNET
      //const PAN   = new Token(ChainId.MAINNET, "0xDa6c746E8a14EFE6E0A031F5d632bd2B0adC28Be", 18); //MAINNET
      //
      //const VVS  = new Token(ChainId.MAINNET, "0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03", 18); //MAINNET

      const tokenIN = new Token(ChainId.MAINNET, swapCoin1.address,swapCoin1.decimals);
      const tokenOUT= new Token(ChainId.MAINNET, swapCoin2.address,swapCoin2.decimals);
      
      const maxHops = multiHops ? 3 : 1 ; // Enable multiHops or not.

      //console.log(ChainId);
      //console.log(WCRO, VVS, pair1, web3Provider);

      try{
        //const pair1   = await Fetcher.fetchPairData(ptCRO, WCRO, web3Provider);
        //const pair2   = await Fetcher.fetchPairData(ytCRO, WCRO, web3Provider);
        //const pair3   = await Fetcher.fetchPairData(PAN,   WCRO, web3Provider);
        //const pair1   = await Fetcher.fetchPairData(VVS, WCRO, web3Provider);
        
        await queryPairs();

        if (tradeType===0) {  // EXACT INPUT

          const inputAmount = new TokenAmount(tokenIN, ethers.utils.parseUnits( amount1.toString() , swapCoin1.decimals));
          const trades = Trade.bestTradeExactIn([pair1, pair2, pair3, pair4, pair5, pair6, pair7, pair8, pair9, pair10], inputAmount, tokenOUT, { maxNumResults: 1, maxHops: maxHops });

          //console.log(amount1.toString(), ethers.utils.parseUnits( amount1.toString() , swapCoin1.decimals), swapCoin1.decimals);
          //const inputAmount = new TokenAmount(tokenIN, ethers.utils.parseEther(amount1.toString()));
          //const trades = Trade.bestTradeExactIn([pair1], inputAmount, tokenOUT, { maxNumResults: 1, maxHops: maxHops }); //TEMP
          
          //
          if (trades && trades.length > 0) {
            const path = trades[0].route.path.map(token => token.address);
            const route = trades[0].route;
            const trade = new Trade(route, inputAmount, TradeType.EXACT_INPUT);
            const slippageTol = new Percent( slippageTolerance*1000, '1000');  // 0.50%

            obj.amount = trades[0].outputAmount.toSignificant(8);                     // Unit: ether
            obj.amountTolerance = trade.minimumAmountOut(slippageTol).raw.toString(); // Unit: wei
            obj.priceInToOut = trades[0].executionPrice.toSignificant(8);
            obj.priceOutToIn = trades[0].executionPrice.invert().toSignificant(8);
            obj.path = path;
            obj.route = trades[0].route;

            //console.log(tokenIN);
            //console.log(tokenOUT);
            //console.log(trades[0].inputAmount.toSignificant(8));
            //console.log(trades[0].outputAmount.toSignificant(8));
            //console.log(trades[0].priceImpact.toSignificant(3));
            //console.log(obj.amountTolerance);
            //console.log(trades[0].executionPrice.toSignificant(8));
            //console.log(trades[0].executionPrice.invert().toSignificant(8));

            //console.log(obj);
            return (obj);
          } else {

            console.log('EXACT_INPUT: No trade found');
            return (obj);
          }
        }
        else {                // EXACT OUTPUT
          
          const outputAmount = new TokenAmount(tokenOUT, ethers.utils.parseUnits( amount2.toString() , swapCoin2.decimals));
          const trades = Trade.bestTradeExactOut([pair1, pair2, pair3, pair4, pair5, pair6, pair7, pair8, pair9, pair10], tokenIN, outputAmount, { maxNumResults: 1, maxHops: maxHops });
          
          //console.log(outputAmount.toSignificant(8));
          //const outputAmount = new TokenAmount(tokenOUT, ethers.utils.parseEther(amount2.toString()));
          //const trades = Trade.bestTradeExactOut([pair1], tokenIN, outputAmount, { maxNumResults: 1, maxHops: maxHops }); //TEMP

          //
          if (trades && trades.length > 0) {
            const path = trades[0].route.path.map(token => token.address);
            const route = trades[0].route;
            const trade = new Trade(route, outputAmount, TradeType.EXACT_OUTPUT);
            const slippageTol = new Percent( slippageTolerance*1000, '1000');  // 0.50%

            obj.amount = trades[0].inputAmount.toSignificant(8);                      // Unit: ether
            obj.amountTolerance = trade.maximumAmountIn(slippageTol).raw.toString();  // Unit: wei
            obj.priceInToOut = trades[0].executionPrice.toSignificant(8);
            obj.priceOutToIn = trades[0].executionPrice.invert().toSignificant(8);
            obj.path = path;
            obj.route = trades[0].route;

            //console.log(trades[0].inputAmount.toSignificant(8));
            //console.log(trades[0].outputAmount.toSignificant(8));
            //console.log(trades[0].priceImpact.toSignificant(3));
            //console.log(trades[0].executionPrice.toSignificant(8));
            //console.log(trades[0].executionPrice.invert().toSignificant(8));
            
            //console.log(obj);
            return (obj);
          } else {

            console.log('EXACT_OUTPUT: No trade found');
            return (obj);
          }                    
        }
          
      } catch (error) {

        console.log(error);
        return (obj);
      }

    }

    async function TokenSwap(amount1, amount1Tolerance, amount2, amount2Tolerance, swapPath, tradeType, coin1_decimals, coin2_decimals) {
      //console.log(amount1, amount1Tolerance, amount2, amount2Tolerance, swapPath, tradeType);

      const Router_contract = new ethers.Contract(uniswapRouterAddress, UniswapV2Router02_ABI, web3Provider.getSigner());

      const path = swapPath;
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;

      var amountIn;
      var amountOut;
      var amountOutMin;
      var amountInMax;

      var tx;
      if (tradeType===0) {  // EXACT INPUT
        amountIn     = ethers.utils.parseUnits(amount1, coin1_decimals).toString();
        amountOut    = 0;
        amountOutMin = amount2Tolerance.toString();
        amountInMax  = 0;
      }
      else {                // EXACT OUTPUT
        amountIn     = 0;
        amountOut    = ethers.utils.parseUnits(amount2, coin2_decimals).toString();;
        amountOutMin = 0;
        amountInMax  = amount1Tolerance.toString();        
      }

      // Transaction
      try{

        if (tradeType===0) {  // EXACT INPUT
          tx = await Router_contract.swapExactTokensForTokens(amountIn, amountOutMin, path, walletAddress, deadline, { gasLimit: 250000 });
        }

        if (tradeType===1) {  // EXACT OUTPUT
          tx = await Router_contract.swapTokensForExactTokens(amountOut, amountInMax, path, walletAddress, deadline, { gasLimit: 250000 });
        }

        if (typeof tx !== 'undefined') {
          //console.log(`Transaction hash: ${tx.hash}`);
          return (tx);
        }
      } catch(error) {
          console.log(error);
          return ;
      }



    }

    async function SwapFunc(amount1, amount1Tolerance, amount2, amount2Tolerance, swapPath, tradeType) {
      var WCRO_contract;
      var depositAmount;
      var withdrawAmount;
      var tx;
      try{

        if      ((swapCoin1.symbol === "CRO")&&(swapCoin2.symbol === "WCRO")) {  // swap for CRO/WCRO
          WCRO_contract = new ethers.Contract(WCRO_address, WCRO_ABI, web3Provider.getSigner());
          depositAmount = ethers.utils.parseEther(amount1).toString();

          tx = await WCRO_contract.deposit( { value: depositAmount, gasLimit: 250000 });
        }
        else if ((swapCoin1.symbol === "WCRO")&&(swapCoin2.symbol === "CRO")) {  // swap for WCRO/CRO
          WCRO_contract  = new ethers.Contract(WCRO_address, WCRO_ABI, web3Provider.getSigner());
          withdrawAmount = ethers.utils.parseEther(amount1).toString();

          tx = await WCRO_contract.withdraw(withdrawAmount, { gasLimit: 250000 });          
        }
        else {
          tx = await TokenSwap(amount1, amount1Tolerance, amount2, amount2Tolerance, swapPath, tradeType, swapCoin1.decimals, swapCoin2.decimals);
        }

        if (typeof tx !== 'undefined') {
          //console.log(`Transaction hash: ${tx.hash}`);
          return (tx);
        }
      } catch(error) {
        console.log(error);
        return ;
      }

    }

    async function addLiquidity(pair, amountADesired, amountBDesired) { //Input unit: ether;
      const Router_contract = new ethers.Contract(uniswapRouterAddress, UniswapV2Router02_ABI, web3Provider.getSigner());

      //const tokenA = new Token(ChainId.MAINNET, pair.tokenA_addr,pair.tokenA_decimals);
      //const tokenB = new Token(ChainId.MAINNET, pair.tokenB_addr,pair.tokenB_decimals);

      var amountADesired_BigN = BigNumber.from(ethers.utils.parseEther(amountADesired));
      var amountBDesired_BigN = BigNumber.from(ethers.utils.parseEther(amountBDesired));

      const amountAMin = amountADesired_BigN.mul( (1-Number(slippageTolerance))*1000 ).div(1000);
      const amountBMin = amountBDesired_BigN.mul( (1-Number(slippageTolerance))*1000 ).div(1000);
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;           

      //console.log(amountADesired_BigN, amountBDesired_BigN, amountAMin, amountBMin);

      try{
        var tx = await Router_contract.addLiquidity(pair.tokenA_addr, pair.tokenB_addr, amountADesired_BigN.toString(), amountBDesired_BigN.toString(),
                                                    amountAMin.toString(), amountBMin.toString(), walletAddress, deadline, { gasLimit: 250000 });

        if (typeof tx !== 'undefined') {
          //console.log(`Transaction hash: ${tx.hash}`);
          return (tx);
        }
      } catch(error) {
          console.log(error);
          return ;
      }

    }

    async function removeLiquidity(pair, amountLPDesired, amountADesired, amountBDesired) { //Input unit: ether;
      const Router_contract = new ethers.Contract(uniswapRouterAddress, UniswapV2Router02_ABI, web3Provider.getSigner());

      const amountLP_BigN = BigNumber.from(ethers.utils.parseEther(amountLPDesired));
      const amountA_BigN  = BigNumber.from(ethers.utils.parseEther(amountADesired));
      const amountB_BigN  = BigNumber.from(ethers.utils.parseEther(amountBDesired));
      const amountAMin = amountA_BigN.mul( (1-Number(slippageTolerance))*1000 ).div(1000);
      const amountBMin = amountB_BigN.mul( (1-Number(slippageTolerance))*1000 ).div(1000);
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;

      try{
        var tx = await Router_contract.removeLiquidity(pair.tokenA_addr, pair.tokenB_addr, amountLP_BigN.toString(), amountAMin.toString(), amountBMin.toString(), walletAddress, deadline, { gasLimit: 250000 });
        
        if (typeof tx !== 'undefined') {
          //console.log(`Transaction hash: ${tx.hash}`);
          return (tx);
        }
      } catch(error) {
        console.log(error);
        return ;
      }

    }

  return {
    calAmountOUT,
    calLiquidity,
    calLPShare,
    calTradePath,
    TokenSwap,
    SwapFunc,
    addLiquidity,
    removeLiquidity
  };
}
