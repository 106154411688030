import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import { AppContext } from "../context";
import axios from 'axios';

export function useNFT() {
  const {
    nfts, setNfts,
    ytCROV2_ADDRESS,
    ytCROV2_ABI,
    PancakeNft_address,
    web3Provider,
    setIsLoading,
    setUserAdditionalAmount,
    setUserAdditionalNftId,
    setUserAdditionalRate,
  } = useContext(AppContext);

  const PancakeNft_ABI  = require("../abis/PancakeNft_abi.json");

  async function getUserNFTs(address) {
    //const url = 'http://localhost:3005/user/' + address + '/nfts';
    const url = 'https://app.creampan.finance/user/' + address + '/nfts';
    const response = await axios.get(url);
  
    if (response.status !== 200) {
      throw new Error('Failed to fetch NFTs');
    }

    setNfts(response.data);
    console.log(nfts);
  }

  async function getNFT(tokenAddress, tokenId) {
    //const url = 'http://localhost:3005';
    const url = 'https://app.creampan.finance/nft/' + tokenAddress + '/' + tokenId;
    const response = await axios.get(url);
  
    if (response.status !== 200) {
      throw new Error('Failed to fetch NFTs');
    }

    //console.log(response.data);
    return (response.data);
  }

  async function getUser(address) {
    //const url = 'http://localhost:3005';
    const url = 'https://app.creampan.finance/user/' + address;
    const response = await axios.get(url);
  
    if (response.status !== 200) {
      throw new Error('Failed to fetch USER');
    }

    //console.log(response.data);
    return response.data; 
  }

  async function addNewUser(address) {
    //const url = 'http://localhost:3005';
    const url = 'https://app.creampan.finance/' + 'addUser';

    const newUser = {
      address: address
    };

    try {
      const response = await axios.post(url, newUser);
      
      // Success response handling
      console.log('User added:', response.data);
      return response.data;

    } catch(error) {
      if (error.response) {
        if (error.response.status === 409) {
          console.log('Conflict: User with this address already exists');
        } else {
          console.log('Failed with status:', error.response.status);
        }

      }
      else if (error.request) {
        console.log('No response received:', error.request);
      }
      else {
        console.log('Error:', error.message);
      }
    }

  }

  async function safeTransferNFT(contractAddress, from, to, tokenId) {
    const ERC721_contract = new ethers.Contract(contractAddress, PancakeNft_ABI, web3Provider.getSigner());

    var tx;

    try {
      //console.log(ERC721_contract.interface.functions);

      const safeTransferFromFunc = ERC721_contract.functions['safeTransferFrom(address,address,uint256)'];
      tx = await safeTransferFromFunc(from, to, Number(tokenId));
      console.log(tx);

    } catch (error) {
      console.error(error);
    }
    return (tx);    
  }

  async function checkIsApprovedForAll(contractAddress, owner, operator) {
    const ERC721_contract = new ethers.Contract(contractAddress, PancakeNft_ABI, web3Provider.getSigner());

    var tx;

    try {
      tx = await ERC721_contract.isApprovedForAll(owner, operator);
      console.log(tx);

    } catch (error) {
      console.error(error);
    }
    return (tx);    
  }

 /* async function setNftApprovalForAll(contractAddress, operator, approval) {                               //This is ok to use as well.
    const ERC721_contract = new ethers.Contract(contractAddress, PancakeNft_ABI, web3Provider.getSigner());

    var tx;
    var approvalChecked;

    approvalChecked = (approval === 'true' || approval === true);

    // Explicitly ensure 'approval' is a boolean
    if (typeof approvalChecked !== 'boolean') {
      console.error('Invalid type for approval. Must be a boolean:', approvalChecked);
      return; // Exit the function if not a boolean
  }

    try {
      tx = await ERC721_contract.setApprovalForAll(operator, approvalChecked, txOptions);
      //console.log(tx);

    } catch (error) {
      console.error(error);
    }
    return (tx);
  }
*/

  async function setNftApprovalForAll(contractAddress, operator, approval) {

    var txResponse;
    const abi = ["function setApprovalForAll(address operator, bool approved)"];
    const iface = new ethers.utils.Interface(abi);

    // Encode the function call with the correct parameters
    const approved = (approval === 'true' || approval === true);       // Boolean approval

    const data = iface.encodeFunctionData("setApprovalForAll", [operator, approved]);

    const transaction = {
      to: contractAddress,  // Address of the contract
      data: data,           // Encoded data from above
      gasLimit: 100000,     // Set your gas limit
      // additional fields as necessary
    };

    try {
    // Send the transaction using a signer
    txResponse = await web3Provider.getSigner().sendTransaction(transaction);

    } catch (error) {
      console.error(error);
    }
    return (txResponse);
  }

  async function NftApprove(contractAddress, operator, tokenId) {
    const ERC721_contract = new ethers.Contract(contractAddress, PancakeNft_ABI, web3Provider.getSigner());

    var tx;

    try {
      tx = await ERC721_contract.approve(operator, tokenId);
      //console.log(tx);

    } catch (error) {
      console.error(error);
    }
    return (tx);
  }

  async function applyNftYieldBoost(nftId) {
    const YTv2_contract = new ethers.Contract(ytCROV2_ADDRESS, ytCROV2_ABI, web3Provider.getSigner());
    let rawTx;

    try {
      rawTx = await YTv2_contract.addNftBoost(Number(nftId));

    } catch (error) {
      console.error(error);
    }

    return(rawTx);

  }

  function calBoostedAmount(amount, nftPower) {
    const boostRate = Number(nftPower)/10000;
    let boostAmount = Number(Number(amount) * Number(boostRate)).toFixed(2);

    return (boostAmount);
  }

  async function mintPancake(amount) {
    setIsLoading(true);
    
    const PancakeNft_contract = new ethers.Contract(PancakeNft_address, PancakeNft_ABI, web3Provider.getSigner());
    var tx;

    try {
      if (/^\d+(\.\d+)?$/.test(amount)) {
        var mintAmount = Number(amount);
      }

      //const estimatedGas = await PancakeNft_contract.estimateGas.mint(mintAmount);

      var gasCal = 600000 * mintAmount;

      const txOptions = {
        //gasLimit: ethers.BigNumber.from(estimatedGas).mul(3)  // Multiplies the estimated gas by 2
        //10000000;
        gasLimit: gasCal
      };

      //console.log(estimatedGas.toString(), txOptions);

      tx = await PancakeNft_contract.mint(mintAmount, txOptions);

      //console.log(tx);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
    return (tx);
  }

  async function getTotalAdditionalAmount() {
    const YTv2_contract = new ethers.Contract(ytCROV2_ADDRESS, ytCROV2_ABI, web3Provider.getSigner());

    let totalAdditionalAmount;
    try {
      let rawTotalAdditionalAmount = await YTv2_contract.totalAdditionalAmount();
      totalAdditionalAmount = { ...rawTotalAdditionalAmount };

    } catch (error) {
      console.error(error);
    }

    totalAdditionalAmount = ethers.utils.formatEther(totalAdditionalAmount).toString();
    //console.log("TotalAdditionalAmount:", totalAdditionalAmount);

    return (totalAdditionalAmount);
  }

  async function getUserInfo() {
    const YTv2_contract = new ethers.Contract(ytCROV2_ADDRESS, ytCROV2_ABI, web3Provider.getSigner());
    const currentAddress = await web3Provider.getSigner().getAddress();

    let userInfo;
    try {
      let rawUserInfo  = await YTv2_contract.userInfo(currentAddress);
      userInfo = { ...rawUserInfo };

    } catch (error) {
      console.error(error);
    }

    userInfo.additionalAmount = Number(ethers.utils.formatEther(userInfo.additionalAmount.toString())).toFixed(3);
    userInfo.additionalRate   = Number(userInfo.additionalRate.toString())/100;

    setUserAdditionalAmount(userInfo.additionalAmount.toString());
    setUserAdditionalNftId(userInfo.additionalNftId.toString());
    setUserAdditionalRate(userInfo.additionalRate.toString());

    return (userInfo);
  }

  return {
    getUserNFTs,
    getNFT,
    getUser,
    addNewUser,
    safeTransferNFT,
    mintPancake,
    getTotalAdditionalAmount,
    getUserInfo,
    calBoostedAmount,
    applyNftYieldBoost,
    checkIsApprovedForAll,
    setNftApprovalForAll,
    NftApprove
  };
}
