import React, { useState, useContext } from "react";
import { ethers } from "ethers";
import { Flex, Button, FormControl, Box, FormLabel, Heading, Input, Text, Spacer, useDisclosure, Image } from "@chakra-ui/react";
import { SettingsIcon } from '@chakra-ui/icons';
import { isBrowser, isMobile} from "react-device-detect";
import { isIOS, isAndroid } from "react-device-detect";
import ClaimButton from "./components/ClaimButton";
import TokenSelect from "./components/TokenSelect";
import TokenModal from "./components/TokenModal";
import CustomSwitchLarge  from "./components/CustomSwitchLarge";

import { useWeb3 } from "./hooks/useWeb3";
import { useIcon } from "./hooks/useIcon";
import { useFactory } from "./hooks/useFactory";

import logo_cronos from "./assets/cronos_light_bg.png";

import { AppContext } from "./context";

function Claim() {
    const {
        web3Provider,
        pendingRewards,
        isLoading,
        walletAddress,
        selectedCoin,
        blockNumber,
        color,
        color2,
        //V2
        userAdditionalAmount,
        userAdditionalNftId,
        userAdditionalRate,
        pendingRewardsV2,
        balanceytCRO,
        balanceytCROV2,
        V1Checked,
        setV1Checked
      } = useContext(AppContext);

      const { isOpen, onOpen, onClose } = useDisclosure();

      const { handleExplorer } = useWeb3();
      const { CircleIcon } = useIcon();
      const { claimRewards } = useFactory();

      const toggleSwitch = () => setV1Checked(!V1Checked);

      return (
        <Box
        className="container"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height={(isMobile) ? (web3Provider) ? "88.3vh" : "92.3vh"
                           : "92.3vh"}
        overflow={(isMobile) ? "scroll" : "hidden" }
        >
          <TokenModal isOpen={isOpen} onClose={onClose} />

          { (isMobile) && 
            <Box
            w="98vw"
            mx="auto"
            position="relative"
            top={(isAndroid) ? "10vh" : "50px"} 
            boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
            borderRadius="1.25rem">
        
              <Flex
                alignItems="center"
                p="1rem 1rem 1rem"
                bg="white"
                color="rgb(86, 90, 105)"
                justifyContent="space-between"
                borderRadius="1.25rem 1.25rem 0 0">

                <Flex alignItems="center">
                  <Text
                    color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
                    fontWeight="bold">
                    {V1Checked ? "ytCRO:" : "ytCROv2:"}
                  </Text>
                </Flex>

                <CustomSwitchLarge checkedBackground="rgb(52,93,157)" uncheckedBackground="rgb(6,95,70)" isChecked={V1Checked} toggleSwitch={toggleSwitch} />

              </Flex>
        
              <Box
                p="0rem 0.4rem 0.4rem 0.4rem"
                bg="white"
                borderRadius="0 0 0rem 0rem"
                >

                <Flex alignItems="center"
                      justifyContent="space-around">

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    bg="rgb(247, 248, 250)"
                    p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "1rem 1rem 1rem"}
                    borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                    _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                    w="40vw">
                    <Box width="20vw" borderColor="black">
                      <Text
                        color="black"
                        fontWeight="bold"
                        fontSize="0.9rem">
                        Boost NFT: 
                      </Text>
                    </Box>

                    <Box width="75px">
                      <Text
                        fontWeight="500"
                        fontSize="1.0rem"
                        width="100%"
                        size="19rem"
                        bg="rgb(247, 248, 250)"
                        textAlign="right"
                        outline="none"
                        border="none"
                        focusBorderColor="none"
                        borderRadius="0.25rem"
                        color="black"
                      >#{V1Checked ? "0" : userAdditionalNftId}</Text>
                    </Box>

                  </Flex>


                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    bg="rgb(247, 248, 250)"
                    p="0.4rem 0.4rem 0.4rem"
                    borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                    _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                    w="50vw">
                    <Box width="26vw" borderColor="black">
                      <Text
                        color="black"
                        fontWeight="bold"
                        fontSize="0.9rem">
                        My Amount: 
                      </Text>
                    </Box>

                    <Box width="100px">
                      <Text
                        fontWeight="500"
                        fontSize="1.0rem"
                        width="100%"
                        size="19rem"
                        bg="rgb(247, 248, 250)"
                        textAlign="right"
                        outline="none"
                        border="none"
                        focusBorderColor="none"
                        borderRadius="0.25rem"
                        color="black"
                      >{V1Checked ? balanceytCRO : balanceytCROV2}</Text>
                    </Box>

                  </Flex>

                </Flex>

              </Box>


              <Box
                p="0.5rem 0.5rem 0.5rem 0.5rem"
                bg="white"
                borderRadius="0 0 0rem 0rem">

                <Flex alignItems="center"
                      justifyContent="space-around">


                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    bg="rgb(247, 248, 250)"
                    p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "1rem 1rem 1rem"}
                    borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                    _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                    w="40vw">
                    <Box width="20vw" borderColor="blue">
                      <Text
                        color="black"
                        fontWeight="bold"
                        fontSize="0.9rem">
                        Boost Rate:
                      </Text>
                    </Box>

                    <Box  width="75px">
                      <Text
                        fontWeight="500"
                        fontSize="1.0rem"
                        width="100%"
                        size="19rem"
                        bg="rgb(247, 248, 250)"
                        textAlign="right"
                        outline="none"
                        border="none"
                        focusBorderColor="none"
                        borderRadius="0.25rem"
                        color="black"
                      >{V1Checked ? "0" : userAdditionalRate}%</Text>
                    </Box>

                  </Flex>

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    bg="rgb(247, 248, 250)"
                    p="0.4rem 0.4rem 0.4rem"
                    borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                    _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                    w="50vw">
                    <Box width="26vw" borderColor="blue">
                      <Text
                        color="black"
                        fontWeight="bold"
                        fontSize="0.9rem">
                        Boost Amount: 
                      </Text>
                    </Box>

                    <Box width="100px">
                      <Text
                        fontWeight="500"
                        fontSize="1.0rem"
                        width="100%"
                        size="19rem"
                        bg="rgb(247, 248, 250)"
                        textAlign="right"
                        outline="none"
                        border="none"
                        focusBorderColor="none"
                        borderRadius="0.25rem"
                        color="black"
                      >{V1Checked ? "0.0" : userAdditionalAmount}</Text>
                    </Box>

                  </Flex>

                </Flex>



              </Box>

              <Flex
                alignItems="center"
                p="0.5rem 1rem 1rem"
                bg="white"
                color="rgb(86, 90, 105)"
                justifyContent="space-between"
                borderRadius="0rem 0rem 0 0">

                  <Flex alignItems="center">
                    <Text
                    color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
                    fontWeight="bold"
                    mr="4px">
                    {V1Checked ? "V1" : "V2" }
                    </Text>
                    
                    <Text
                      color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
                      fontWeight="bold">
                      Rewards:
                    </Text>
                  </Flex>

              </Flex>

              <Box
                p="0rem 0.5rem 0.5rem 0.5rem"
                bg="white"
                borderRadius="0 0 1.25rem 1.25rem">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "1rem 1rem 1rem"}
                  borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box>
                    <Text
                      color="black"
                      fontWeight="bold">
                      {(selectedCoin.symbol === "") ? "CRO" : selectedCoin.symbol}:
                    </Text>
                  </Box>

                  <Box width="86%">
                    <Text
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      bg="rgb(247, 248, 250)"
                      textAlign="right"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      color="black"
                    >{V1Checked ? pendingRewards : pendingRewardsV2}</Text>
                  </Box>
                </Flex>
              </Box>

            </Box>
          }

          { (!isMobile) && 
            <Box
            w={(isMobile) ? "98vw" : "46rem"}
            mx="auto"
            position="relative"
            top={(isAndroid) ? "30px" : "50px"} 
            boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
            borderRadius="1.25rem">
        
              <Flex
                alignItems="center"
                p="1rem 1rem 1rem"
                bg="white"
                color="rgb(86, 90, 105)"
                justifyContent="space-between"
                borderRadius="1.25rem 1.25rem 0 0">

                <Flex alignItems="center">
                  <Text
                    color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
                    fontWeight="bold">
                    {V1Checked ? "ytCRO:" : "ytCROv2:"}
                  </Text>
                </Flex>

                <CustomSwitchLarge checkedBackground="rgb(52,93,157)" uncheckedBackground="rgb(6,95,70)" isChecked={V1Checked} toggleSwitch={toggleSwitch} />

              </Flex>
        
              <Box
                p="0rem 0.5rem 0.5rem 0.5rem"
                bg="white"
                borderRadius="0 0 0rem 0rem">

                <Flex alignItems="center"
                      justifyContent="space-around">

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    bg="rgb(247, 248, 250)"
                    p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "1rem 1rem 1rem"}
                    borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                    _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                    <Box width="6vw" borderColor="black" border="3px">
                      <Text
                        color="black"
                        fontWeight="bold">
                        Boost NFT: 
                      </Text>
                    </Box>

                    <Box width="140px">
                      <Text
                        fontWeight="500"
                        fontSize="1.4rem"
                        width="100%"
                        size="19rem"
                        bg="rgb(247, 248, 250)"
                        textAlign="right"
                        outline="none"
                        border="none"
                        focusBorderColor="none"
                        borderRadius="0.25rem"
                        color="black"
                      >#{V1Checked ? "0" : userAdditionalNftId}</Text>
                    </Box>

                  </Flex>

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    bg="rgb(247, 248, 250)"
                    p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "1rem 1rem 1rem"}
                    borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                    _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                    <Box width="6vw" borderColor="black" border="3px">
                      <Text
                        color="black"
                        fontWeight="bold">
                        My Amount: 
                      </Text>
                    </Box>

                    <Box width="140px">
                      <Text
                        fontWeight="500"
                        fontSize="1.4rem"
                        width="100%"
                        size="19rem"
                        bg="rgb(247, 248, 250)"
                        textAlign="right"
                        outline="none"
                        border="none"
                        focusBorderColor="none"
                        borderRadius="0.25rem"
                        color="black"
                      >{V1Checked ? balanceytCRO : balanceytCROV2}</Text>
                    </Box>

                  </Flex>

                </Flex>

              </Box>


              <Box
                p="0.5rem 0.5rem 0.5rem 0.5rem"
                bg="white"
                borderRadius="0 0 0rem 0rem">

                <Flex alignItems="center"
                      justifyContent="space-around">

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    bg="rgb(247, 248, 250)"
                    p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "1rem 1rem 1rem"}
                    borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                    _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                    <Box width="6vw" borderColor="blue" border="3px">
                      <Text
                        color="black"
                        fontWeight="bold">
                        Boost Rate:
                      </Text>
                    </Box>

                    <Box  width="140px">
                      <Text
                        fontWeight="500"
                        fontSize="1.4rem"
                        width="100%"
                        size="19rem"
                        bg="rgb(247, 248, 250)"
                        textAlign="right"
                        outline="none"
                        border="none"
                        focusBorderColor="none"
                        borderRadius="0.25rem"
                        color="black"
                      >{V1Checked ? "0" : userAdditionalRate}%</Text>
                    </Box>

                  </Flex>

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    bg="rgb(247, 248, 250)"
                    p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "1rem 1rem 1rem"}
                    borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                    _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                    <Box width="8vw" borderColor="blue" border="3px">
                      <Text
                        color="black"
                        fontWeight="bold">
                        Boost Amount: 
                      </Text>
                    </Box>

                    <Box width="100px">
                      <Text
                        fontWeight="500"
                        fontSize="1.4rem"
                        width="100%"
                        size="19rem"
                        bg="rgb(247, 248, 250)"
                        textAlign="right"
                        outline="none"
                        border="none"
                        focusBorderColor="none"
                        borderRadius="0.25rem"
                        color="black"
                      >{V1Checked ? "0.0" : userAdditionalAmount}</Text>
                    </Box>

                  </Flex>

                </Flex>



              </Box>

              <Flex
                alignItems="center"
                p="0.5rem 1rem 1rem"
                bg="white"
                color="rgb(86, 90, 105)"
                justifyContent="space-between"
                borderRadius="0rem 0rem 0 0">

                  <Flex alignItems="center">
                    <Text
                    color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
                    fontWeight="bold"
                    mr="4px">
                    {V1Checked ? "V1" : "V2" }
                    </Text>
                    
                    <Text
                      color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
                      fontWeight="bold">
                      Rewards:
                    </Text>
                  </Flex>

              </Flex>

              <Box
                p="0rem 0.5rem 0.5rem 0.5rem"
                bg="white"
                borderRadius="0 0 1.25rem 1.25rem">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "1rem 1rem 1rem"}
                  borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box>
                    <Text
                      color="black"
                      fontWeight="bold">
                      {(selectedCoin.symbol === "") ? "CRO" : selectedCoin.symbol}:
                    </Text>
                  </Box>

                  <Box width="86%">
                    <Text
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      bg="rgb(247, 248, 250)"
                      textAlign="right"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      color="black"
                    >{V1Checked ? pendingRewards : pendingRewardsV2}</Text>
                  </Box>
                </Flex>
              </Box>

            </Box>
          }

          <Box
          w={(isMobile) ? "98vw" : "46rem"}
          mx="auto"
          position="relative"
          top={(isMobile) ? ( (isAndroid) ? "15vh" : "10vh" ) : "0px"}
          boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
          borderRadius="1.25rem">
      
            <Flex
              alignItems="center"
              p="1rem 1rem 1rem"
              bg="white"
              color="rgb(86, 90, 105)"
              justifyContent="space-between"
              borderRadius="1.25rem 1.25rem 0 0">
              
              <Flex alignItems="center"> 
                <Text
                  color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
                  fontWeight="bold"
                  mr="4px">
                  Claim Rewards of ytCRO
                </Text>

                <Text
                  color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
                  fontWeight="bold">
                  {V1Checked ? "V1" : "V2" }
                </Text>
              </Flex>

              <Flex alignItems="center"> 

                <CustomSwitchLarge checkedBackground="rgb(52,93,157)" uncheckedBackground="rgb(6,95,70)" isChecked={V1Checked} toggleSwitch={toggleSwitch} />

              </Flex>

            </Flex>
      
            <Box
              p="0rem 0.5rem 0.5rem 0.5rem"
              bg="white"
              borderRadius="0 0 0rem 0rem">
              <Flex
                alignItems="center"
                justifyContent="space-between"
                bg="rgb(247, 248, 250)"
                p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "1rem 1rem 1rem"}
                borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                <Box>
                  <Text
                    color="black"
                    fontSize={(isMobile) ? "0.8rem" : "1rem"}
                    fontWeight="bold">
                    Address:
                  </Text>
                </Box>

                <Box width="86%">
                  <Text
                    fontWeight="500"
                    fontSize={(isMobile) ? "0.8rem" : "1.4rem"}
                    width={(isMobile) ? "98%" : "100%"}
                    size="19rem"
                    bg="rgb(247, 248, 250)"
                    textAlign="right"
                    outline="none"
                    border="none"
                    focusBorderColor="none"
                    borderRadius="0.25rem"
                    color="black"
                  >{walletAddress}</Text>
                </Box>
              </Flex>

              <Flex
                alignItems="center"
                justifyContent="space-between"
                bg="rgb(247, 248, 250)"
                p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "1rem 1rem 1rem"}
                mt="0.25rem"
                borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                height="75px"
                _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                >
                <Box>
                  <Text
                    color="black"
                    fontSize={(isMobile) ? "0.8rem" : "1rem"}
                    fontWeight="bold">
                    With:
                  </Text>
                </Box>
                <Box>
                  <Input
                    placeholder=""
                    fontWeight="500"
                    fontSize="1.4rem"
                    width="100%"
                    size="19rem"
                    textAlign="right"
                    bg="rgb(247, 248, 250)"
                    outline="none"
                    border="none"
                    focusBorderColor="none"
                    borderRadius="0.25rem"
                    type="text"
                    inputMode="decimal"
                    color="black"
                    value={""}
                    isDisabled
                    style={{ boxShadow: "none", cursor: "auto" }}
                  />
                </Box>
                <Box ml="0.5rem">
                <TokenSelect openTokenModal={onOpen} value={selectedCoin.symbol} image={selectedCoin.icon}/>
                </Box>
              </Flex>

            </Box>
      
            <ClaimButton onClick={claimRewards} isLoading={isLoading} text="Claim"/> 
          </Box>

          {(isMobile) && (
            <Box w="30px" mt="10vh" h="5vh">
            </Box>
            )}

          <Flex bottom="0" justify="space-between" alignItems="center" width="100vw" mb={(isAndroid) ? "1.6rem" : "0.2rem"} mt={(isAndroid) ? "2.2rem" : "0.2rem"}>

            <Flex mr="16px" w="90px">
            </Flex>

            <Flex alignItems="center">
              <Text fontSize={(isMobile) ? '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.4rem" >powered by</Text>
              <Box width="85px" height="30px">
                <Image src={logo_cronos} fit="contain"/>
              </Box>
            </Flex>

            <Flex alignItems="center">
              {web3Provider && (
              <Flex alignItems="center">
              <Button h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color={color2} mr="0.3rem" p="0" onClick={() => handleExplorer( ("/block/" + blockNumber) )}>{blockNumber}</Button>
              <CircleIcon boxSize={3} color={color} mr={(isMobile) ? '0px' : '16px'}/>
              </Flex>
              )}
              {!web3Provider && (
              <Flex alignItems="center">
              <Text h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="transparent" mr="0.3rem" p="0">{blockNumber}</Text>
              <CircleIcon boxSize={3} color="transparent" mr={(isMobile) ? '0px' : '16px'}/>
              </Flex>
              )}
            </Flex>

          </Flex>
        
        </Box>
      );
  
}

export default Claim;
